import React, { useEffect } from "react";
import { Box, Text,  ChakraProvider } from "@chakra-ui/react";
import { motion } from "framer-motion";

const TermsOfServicePage = () => {
        useEffect(() => {
                window.scrollTo(0, 0);
              }, []);
  return (
    <ChakraProvider>
         <motion.div
        
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: 1, height: "auto" }}
        exit={{ opacity: 0, height: 0 }}
        transition={{ duration: 0.9 }}
      >
      <Box
        maxW="full"
        mx="auto"
      
        p={4}
        bg="	#e9e9e9"
        style={{ fontFamily: "Nunito Sans" }}
      >
        <Text fontSize="xl" fontWeight="bold" mb="4" textAlign="center" textDecoration="underline">
          PRIVACY POLICY
        </Text>
        <Text fontSize="xl" fontWeight="bold" mt="4" mb="2" >
        Definitions
        </Text>
        <Text>
        “We,&quot; &quot; Us”, “Our”, and “Company” shall refer to MY CITY PEOPLE Inc., our subsidiaries and
affiliates, and any of our respective agents and third-party service providers (“service
providers”).<br/>
“You&quot; means the individual accessing or using the Service, the Company, or other legal entity
on behalf of which such individual is accessing or using the Service, as applicable.
        </Text>
        <Text fontSize="xl" fontWeight="bold" mt="4" mb="2" >
        Collection of Information
        </Text>
        <Text mb="2">
          (a) We collect your personal information to maximize on quality service delivery to you,
provide you with any additional or special information about our services and protect
your account and personal information.
        </Text>
        <Text mb="2">
          (b)We may collect the following kinds of information when you, your colleagues, or

other users access the Service:
        </Text>
        <Text mb="2"> (c)Your contact information such as full name and email address
        <Text>- The Content, communications, and other information you
provide when you use Our Service. This can include
information in or about the Content you provide;</Text>
        <Text>- user communications, feedback, suggestions, and ideas sent
to you;</Text>
        <Text>- credit information; and</Text>
        <Text>- Information that you provide when you engage the platform
support regarding the Service.</Text>
        <Text>  - We may collect personal information about you from the information you provide to
us when you fill out an Application or other forms on our website. We may also receive
personal information about you from third-party services such as financial institutions
and credit bureaus. Lastly, we may also collect personal information from individuals
whose identity you share with us.</Text>
<Text>
You permit us to collect your personal information at any time, before, during, and
after engaging in a business relationship with us.
</Text>
<Text>
Our servers also collect information from you, such as your domain name, websites
you visit, and Internet Protocol address. This information is not personally
identifiable. However, when you respond to questions or communicate with us, your
personal information may be collected.
</Text>
   
   
   
 
        </Text>
        <Text fontSize="xl" fontWeight="bold" mb="2">
        Use of Information
</Text>
        <Text mb="10">
          <Text>
          To effectively manage Your Account: to manage Your registration
as a user of the Our services;
          </Text>
          <Text>
          To provide you with details about our services by email,
text, phone, and through other communication methods;
          </Text>
          <Text>
          to carry out product development, statistical analysis, and
market research;
          </Text>
          <Text>
          to develop and improve our services;
          </Text>
       <Text>
       to update our records;
       </Text>
       <Text>
       to carry out checks required by applicable regulations or
regulatory guidance;
       </Text>
       <Text>
       to improve our relationship with you by making the website
available to you in a user-friendly way, and to identify services
you may be interested in;
       </Text>
       <Text>
       for customer service, including answering questions and
responding to feedback and complaints;
       </Text>
       <Text>
       Where you have given us consent, we shall provide you
with information about any new services, promotions, and other
information that we think will be of interest to you. You can
withdraw your consent at any time, but without affecting the

the lawfulness of processing based on consent before its withdrawal. You can update your details or change your privacy preferences by
contacting us via the details given in the &quot; Contact Us&quot; section
below.
       </Text>
        </Text>
        <Text fontSize="xl" fontWeight="bold" mb="2">
        SHARING PERSONAL INFORMATION
</Text>
        <Text mb="1">
       . We won’t share your personal information.
        </Text>
        <Text mb="1">
        . We may share your information with analytics and search engine
providers that assist us in the improvement and optimization of our website;
<Text>
  <Text mb="1">
  . We may share alerts and information derived from identity verification

checks with third parties for anti-money laundering and fraud
prevention;
  </Text>
<Text mb="1">
 . We may disclose your personal information on request to the police or
any other regulator or government authority to fulfil our regulatory
responsibilities, to help prevent or detect fraud or any other type of
crime, or for any other reasonable purpose identified by the relevant
authority;
</Text>
<Text mb="2">
. Except as set out in this privacy policy; we will not sell or disclose your
data to any third party;
</Text>
<Text mb="2">
. We may share your information in the event of a merger, acquisition, or
sale of our assets. We shall communicate with you in the event of such
a change.
</Text>
</Text>
        </Text>
        <Text fontSize="xl" fontWeight="bold" mb="2">
        LINKS TO OTHER WEBSITES
</Text>
        <Text mb="10">
          <Text>
         - Our Program and Website may contain links to other websites that are not
operated by us. If you click on a third-party link, you will be directed to that third
party&#39;s website. We strongly advise you to review the Privacy Policy of every website you
visit.
          </Text>
          <Text>
        -  We have no control over and assume no responsibility for the Content,
privacy policies, or practices of any third-party websites or services.
          </Text>
     
        </Text>
        <Text fontSize="xl" fontWeight="bold" mt="4" mb="2" >
        SECURITY OF YOUR PERSONAL INFORMATION
        </Text>
        <Text mb="10">
          <Text>
-  The security of Your Personal Data is important to us but remember that no
transmission method over the Internet or method of electronic storage is 100% secure.
While we strive to use commercially acceptable means to protect Your Personal Data,
we cannot guarantee its absolute security.
          </Text>
          <Text>
            - We restrict access to the information obtained from our websites and web pages to
our employees, agents, Affiliates, Partners and contractors. We maintain physical,
electronic, and procedural safeguards designed to protect personal information to the
extent reasonably possible.
          </Text>
       
        </Text>
        <Text fontSize="xl" fontWeight="bold" mb="2">
        Changes to Privacy Policy
</Text>
        <Text mb="10">
          <Text>
      - We may update our Privacy Policy from time to time. We will notify you of any
changes by posting the new Privacy Policy on this page.
          </Text>
     
<Text>
- We will let you know via email or a prominent notice on Our Program and Website
before or when the changes become effective and update the &quot;Last Updated&quot; date at
the top of this Privacy Policy.       
          </Text>
          <Text>
         - You are advised to review this Privacy Policy periodically for any changes.
Changes to this Privacy Policy are significant when they are posted on this page.
          </Text>
        </Text>
      </Box>
      </motion.div>
    </ChakraProvider>
  );
};

export default TermsOfServicePage;
