import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import Home from "./component/Homepage/Home";

import "./css/index.css";
import Faqone from "./component/FaqPage/Faqone";
import Faqtwo from "./component/FaqPage/Faqtwo";
import Faqthree from "./component/FaqPage/Faqthree";
import Privacy from "./component/privacy/Privacy";
import Terms from "./component/Terms&service/Terms";
import DeleteAccount from "./component/DeleteAccount/DeleteAccount";
// import Asset from './.well-known/assetlinks.json';
 

function App() {
  return (
    <BrowserRouter basename="/">
      <ChakraProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/faq-one" element={<Faqone />} />
          <Route path="/faq-two" element={<Faqtwo />} />
          <Route path="/faq-three" element={<Faqthree />} />
          <Route path="/termas-a" element={<Terms />} />
          <Route path="/privacy-p" element={<Privacy />} />
          <Route path="/delete-account" element={<DeleteAccount />} />
          

        </Routes>
       
      </ChakraProvider>
   
    </BrowserRouter>
  );
}

export default App;
