import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import 'bootstrap/dist/css/bootstrap.min.css';

const DeleteAccount = () => {
    console.log('DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD')
    const [email, setEmail] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://mcpadminpanel-5dc5c1dde584.herokuapp.com/public/request-account-delete', { email }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
            if (response.data) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Account deletion request sent successfully',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to send account deletion request email',
                });
            }
        } catch (error) {
            console.error('There was an error!', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An error occurred. Please try again later.',
            });
        }
    };

    return (
        <div className="container mt-5">
            <h2 className="mb-4">MCP Account Deletion Request</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <div className="row">

                    <div className="col-sm-3">
                        
                    </div>

                    <div className="col-sm-6">
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control"
                        required
                    />
                   </div> 
                   </div>
                </div>
                <button type="submit" className="btn btn-danger mt-3">Submit</button>
            </form>
        </div>
    );
};

export default DeleteAccount;
