import React from "react";
import About from "../About/About";
// import { ChakraProvider } from "@chakra-ui/react";
import Af from "../AfNav/Af";
import Navbar from "../Nav/Navbar";
import Work from "../work/Work";
import Support from "../support/Support";
import Points from "../points/Points";
import Comit from "../commitment/Comit";
import Faq from "../faq/Faq";
import Slide from "../slide/Slide";
import Down from "../download/Down";
import Futer from "../foot/Futer";
import "../../css/index.css";



function Home() {
  return (
    <div>
      <Navbar />
      <Af />
      <About />
      <Work />
      <Support />
      <Points />
      <Comit />
      {/* <Faq /> */}
      {/* <Slide />
      <Down /> */}
      <Futer />
    </div>
  );
}

export default Home;
