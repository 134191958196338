import {
  Container,
  Stack,
  Flex,
  Heading,
  Text,
  Button,
  Image,
  Box,
  Link,
} from "@chakra-ui/react";
import getonplaystore from "./get_started_play_store.png";
import getonappstore from "./ios_app_store.png";

import { motion } from "framer-motion";
import pic from './a.jpg'

export default function CallToActionWithVideo() {
  const scrollToSection = () => {

    //Get User to Play Store
    window.location ="https://play.google.com/store/apps/details?id=com.appmcp&pli=1";
    return true;

    // Find the section by its id
    const section = document.getElementById("WorkSection");

    // Check if the section exists
    if (section) {
      // Use smooth scrolling to scroll to the section
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
   
    <Container
      id="Home"
      paddingBottom="73px"
      maxW={"full"}
     
      marginTop="30px"
      backdropContrast="10%"
      backgroundImage={pic}
      // backgroundBlendMode="multiply" // Add blend mode for dim effect
      // filter="brightness(0.76)" // Adjust brightness for dim effect
      backgroundSize={"cover"}
      backgroundPosition={"center center"}
    >
       <motion.div
        
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: 1, height: "auto" }}
        exit={{ opacity: 0, height: 0 }}
        transition={{ duration: 0.9 }}
      >
      <Stack
        align={"center"}
        // ml="15px"
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
        direction={{ base: "column", md: "row" }}
      >
        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            ml="12px"
            fontSize={{ base: "3xl", sm: "4xl", lg: "4xl" }}
          >
            <Text
              as={"span"}
              color="white"
              style={{ fontFamily: "Nunito Sans" }}
              position={"relative"}
              _after={{
                content: "''",
                width: "full",
                height: "30%",
                position: "absolute",
                bottom: 1,
                color: "#FFFFFF",
                left: 0,
                zIndex: -1,
              }}
            >
             
            Welcome to 'My City People'
            </Text>
            <br />
            <Text
              style={{ fontFamily: "Nunito Sans" }}
              as={"span"}
              color={"#FFFFFF"}
            >
           Real Connections, Real Life
            </Text>
            <Text
              style={{ fontFamily: "Nunito Sans" }}
              as={"span"}
              color={"#FFFFFF"}
            >
             <br /> Join 'My City People',
            </Text>
          </Heading>

          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={{ base: "column", sm: "row" }}
          >
          <Box margin="">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Link href="https://play.google.com/store/apps/details?id=com.appmcp&pli=1">
                <Image width="300px" src={getonplaystore} style={{ marginRight: '10px' }} />
              </Link>
              <Link href="https://apps.apple.com/us/app/mycitypeople/id1667277538?platform=iphone">
                <Image width="250px" src={getonappstore}  />
              </Link>
          </div>
          
          </Box>

  
          </Stack>
          
        </Stack>
        <Flex
          flex={1}
          justify={"center"}
          align={"center"}
          position={"relative"}
          w={"full"}
        ></Flex>
      </Stack>
      </motion.div>
    </Container>
   
  );
}

