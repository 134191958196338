import React, { useEffect } from "react";
import { Box, Text, Link, ChakraProvider } from "@chakra-ui/react";
import { motion } from "framer-motion";

const Page = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ChakraProvider>
       <motion.div
        
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: 1, height: "auto" }}
        exit={{ opacity: 0, height: 0 }}
        transition={{ duration: 0.9 }}
      >
      <Box
        maxW="full"
        mx="auto"
        p={4}
        bg="#e9e9e9"
        fontFamily="Nunito Sans"
      >
        <Text fontSize="xl" fontWeight="bold" mb="4" textAlign="center" textDecoration="underline">
          TERMS AND CONDITIONS OF MCP
        </Text>
        <Text mb="4">Date of Last Revision: NOV 8, 2023</Text>
        <Text mb="4">
          Before reading our terms and conditions you need to understand the following terms:
        </Text>
        <Text fontSize="lg" fontWeight="bold" mb="4">
          Terms
        </Text>
        <Text mb="4">
        These Terms and Conditions, together with any and all other documents referred to herein, set out the terms of use under which you may use this website, [Web address].
          Please read these Terms and Conditions carefully and ensure that you understand them.
          Your agreement to comply with and be bound by these Terms and Conditions is deemed to occur upon your first use of Our Website. If you do not agree to comply with and be bound by these Terms and Conditions, you must stop using Our Website immediately.
       
{" "}
          <Link color="blue.500" href="mailto:support@mcp.com">
            support@appmcp.com
          </Link>
          .
        </Text>
        
        <Text fontSize="lg" fontWeight="bold" mb="4">
        Definitions and Interpretation:
        </Text>
        <Text mb="4">
        In these Terms and Conditions, unless the context otherwise requires, the following expressions have the following meanings:.<br/>

- "Account" Means an account required for a User to access certain areas of Our Website.<br/>
- "Content" means any and all text, images, audio, video, scripts, code, software, databases and any other form of information capable of being stored on a computer that appears on, or forms part of, Our Website;.<br/>
- "Our Website" Means this website, [Web address], and a reference to "Our Website" includes reference to any and all Content included therein (including User Content, unless expressly stated otherwise);.<br/>
        </Text>
        <Text fontSize="lg" fontWeight="bold" mb="4">
        Access To Our Website:
        </Text>
        <Text mb="4">
        a. Access to Our Website is free of charge. <br/>
        b. It is your responsibility to make any and all arrangements necessary in order to access Our Website. <br/>
          c. Access to Our Website is provided "as is" and on an "as available" basis. We may alter, suspend, or discontinue Our Website (or any part of it) at any time and without notice. We will not be liable to you in any way if Our Website (or any part of it) is unavailable at any time and for any period. <br/>
        </Text>
        <Text fontSize="lg" fontWeight="bold" mb="4">
        Accounts
        </Text>
        <Text mb="4">
       
        </Text>
        <Text>
        a. Certain parts of Our Website (including the ability to submit User Content) may require an Account in order to access them. <br/>
  b. You may not create an Account if you are under 13 years of age. If you are under 13 years of age and wish to use the parts of Our Website that require an Account, your parent or guardian should create the Account for you and you must only use the Account with their supervision. <br/>
  c. When creating an Account, the information you provide must be accurate and complete. If any of your information changes at a later date, it is your responsibility to ensure that your Account is kept up-to-date. <br/>
  d. We require that you choose a strong password for your Account, consisting of a combination of lowercase and uppercase letters and numbers. It is your responsibility to keep your password safe. You must not share your Account with anyone else. If you believe your Account is being used without your permission, please contact us immediately. <br/>
  e. We will not be liable for any unauthorized use of your Account. <br/>
  f. You must not use anyone else's Account without the express permission of the User to whom the Account belongs. <br/>
  g. Any personal information provided in your Account will be collected, used, and held in accordance with your rights and our obligations under the Data Protection Act. <br/>
  h. If you wish to close your Account, you may do so at any time. Closing your Account will result in the removal of your information. Closing your Account will also remove access to any areas of Our Website requiring an Account for access. In accordance with the privacy policy, all data associated with your account will be removed when your account is closed. <br/>
        </Text>
        
        {/* Additional Terms */}
        <Text fontSize="xl" fontWeight="bold" mt="4" mb="2" >
        Website Intellectual Property Rights:
        </Text>
        <Text>
          (a) With the exception of User Content, all Content on Our Website and the copyright and other intellectual property rights subsisting in that Content, unless specifically labeled otherwise, belongs to or has been licensed by us. All Content (including User Content) is protected by applicable Canadian and international intellectual property laws and treaties.
        </Text>
        <Text>
          (b) Subject to the license granted to Us hereunder, Users retain the ownership of copyright and other intellectual property rights subsisting in User Content submitted by them (unless any part of such User Content is owned by a third party who has given their express permission for their material to be used in the User Content).
        </Text>
        <Text>
          (c) For personal use (including research and private study) only, you may:
        </Text>
        <Text>
        . Access, view, and use Our Website in a web browser (including any web browsing capability built into other types of software or app);<br/>
    . Download any Content where we have provided a link enabling you to do so;<br/>
    . Download Our Website (or any part of it) for caching;<br/>
    . Save pages from Our Website for later and/or offline viewing;<br/>
    . View and use User Content in accordance with the permissions displayed with that User Content.<br/>
        </Text>
        <Text fontSize="lg" fontWeight="bold"  mt="4" mb="2" > 
        Termination
        </Text>
        <Text>
        We reserve the right, at its sole discretion, to immediately and without notice suspend
or permanently deny your access to all or part of the Website and associated services.
The obligations contained herein will continue to apply to your past use.
Upon termination or suspension or denial of access to the Website and associated
services, funds already received on your behalf by us will be handled in accordance
with the provisions above, provided that any potential payment (where applicable)
may be delayed where we conduct an investigation regarding your use of the Website
and associated services and we may decide to refuse to pay funds across to you where
you have breached these Terms. In such circumstances we may authorise a refund of
donations to donors.
        </Text>
      
      </Box>
      </motion.div>
    </ChakraProvider>
  );
};

export default Page;
