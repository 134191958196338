import React, { useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  Drawer,
  Image,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import PhillaFun from "../Nav/BrandImg/Group.png";
import { motion } from "framer-motion";

const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const handleScroll = () => {
      const nav = document.getElementById("navbar");
      if (nav && window.scrollY > 0) {
        nav.classList.add("scrolled");
      } else if (nav) {
        nav.classList.remove("scrolled");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const offset = 60; // Adjust the offset value as needed
      const elementPosition = element.offsetTop - offset;
      window.scrollTo({
        top: elementPosition,
        behavior: "smooth",
      });
    }
    onClose(); // Close the drawer after clicking on a button
  };

  return (
    <Box
    // "linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%)"
      id="navbar"
      bg="linear-gradient(90deg, rgba(185,31,168,1) 0%, rgba(217,112,179,1) 43%, rgba(238,166,186,1) 87%)"
      px={20}
      py={3}
      position="fixed"
      top={0}
      width="100%"
      zIndex={999}
      transition="background-color 0.3s ease"
      className="navbar"
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <Text fontSize="xl" fontWeight="bold" color="white" >
            <Image
              onClick={() => handleScroll("Home")}
              w="45px"
              h="45px"
             marginRight={{base:"200px",lg:"auto"}}
              borderRadius={30}
              src={PhillaFun}
              cursor="pointer"
            />
          </Text>
         
          <Flex
            display={{ base: "none", md: "flex" }}
            fontWeight="100"
            ml="460"
            alignItems="center"
            overflowX="auto"
            whiteSpace="nowrap"
          >
             <motion.div
        
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: 1, height: "auto" }}
        exit={{ opacity: 0, height: 0 }}
        transition={{ duration: 1.1 }}
      >
            <Button
              style={{ fontFamily: "Nunito Sans" }}
              bg=""
              _hover=""
              color="#ffffff"
              onClick={() => handleScroll("Home")}
            >
              Home
            </Button>
            </motion.div>
            <motion.div
        
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: 1, height: "auto" }}
        exit={{ opacity: 0, height: 0 }}
        transition={{ duration: 1.3 }}
      >
            <Button
              style={{ fontFamily: "Nunito Sans" }}
              bg=""
              _hover=""
              color="#ffffff"
              onClick={() => handleScroll("AboutSection")}
            >
              About
            </Button>
            </motion.div>
            <motion.div
        
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: 1, height: "auto" }}
        exit={{ opacity: 0, height: 0 }}
        transition={{ duration: 1.5 }}
      >
            <Button
              style={{ fontFamily: "Nunito Sans" }}
              bg=""
              _hover=""
              color="#ffffff"
              onClick={() => handleScroll("WorkSection")}
            >
              How it Works
            </Button>
            </motion.div>
           
          </Flex>
          
        </Flex>
      
        <Box  display={{ base: "block", md: "none" }}>
          <Button onClick={onOpen}  variant="ghost" ml={8}>
            <HamburgerIcon  w={6} h={6} />
          </Button>
          <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
            <DrawerOverlay>
              <DrawerContent
                borderRadius={20}
                // h="40vh"
              
                bg="linear-gradient(90deg, rgba(185,31,168,1) 0%, rgba(217,112,179,1) 43%, rgba(238,166,186,1) 87%)"
              >
                <DrawerCloseButton color="white" />
                <DrawerHeader color="yellow.400" fontWeight={600}>
                  <Image   w="45px"
              h="45px" borderRadius="full" src={PhillaFun} />
                </DrawerHeader>
                <DrawerBody    bg="linear-gradient(90deg, rgba(185,31,168,1) 0%, rgba(217,112,179,1) 43%, rgba(238,166,186,1) 87%)">
                  <Flex direction="column">
                    <Button
                      color="white"
                      variant="ghost"
                      my={2}
                      onClick={() => handleScroll("Home")}
                    >
                      Home
                    </Button>
                    <Button
                      color="white"
                      variant="ghost"
                      my={2}
                      onClick={() => handleScroll("AboutSection")}
                    >
                      About
                    </Button>
                    <Button
                      color="white"
                      variant="ghost"
                      my={2}
                      onClick={() => handleScroll("WorkSection")}
                    >
                      How it works
                    </Button>
                   
                  </Flex>
                </DrawerBody>
              </DrawerContent>
            </DrawerOverlay>
          </Drawer>
        </Box>
      </Flex>
    </Box>
  );
};

export default Navbar;

