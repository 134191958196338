import React from "react";
import {
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  
} from "@chakra-ui/react";
import Groupfour from "../commitment/cImg/raw.webp";

export default function SplitScreen() {
  return (
    <Stack
      minH={"40vh"}
      //   bg={'linear-gradient(90.62deg, #3BA98B 10.11%, #216C65 106.67%)'}
      direction={{ base: "column", md: "row" }}
    >
      <Flex direction="column-reverse" flex={1}>
        {/* second image */}
        <Image
          width="55.5%"
          mb="73px"
          ml="10%"
          mt={{base:"50px",lg:""}}
          borderRadius="2%"
          alt={"Login Image"}
          objectFit={"cover"}
          src={Groupfour}
        />
      </Flex>

      <Flex p={8} flex={1} align={"center"} justify={"center"}>
        <Stack spacing={6} w={"full"} maxW={"lg"}>
        <Heading fontSize={{ base: "3xl", md: "3xl", lg: "3xl" }}>
  <Text
    style={{ fontFamily: "Nunito Sans" }}
    fontWeight="600"
    color="#2F706D"
    as={"span"}
  >
  OUR VISION


  </Text>
  {/* <br />{" "}
  <Text
    style={{ fontFamily: "Nunito Sans" }}
    fontWeight="600"
    color={"#2F706D"}
    as={"span"}
  >
    giving and become a
  </Text>{" "}
  <Text
    style={{ fontFamily: "Nunito Sans" }}
    fontWeight="600"
    color={"#2F706D"}
    as={"span"}
  >
    philanthropist.
  </Text>{" "} */}
</Heading>

          <Text
            style={{ fontFamily: "Nunito Sans" }}
            fontSize={{ base: "md", lg: "lg" }}
            color={"#2F706D"}
          >
         Our vision at 'My City People' is to revolutionize the way individuals experience and thrive in new cities. We envision a world where every person who relocates to a different city finds a welcoming community and a network of like-minded individuals.




          </Text>
        </Stack>
      </Flex>
    </Stack>
  );
}
