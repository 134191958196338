import React from "react";
import {
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  // useBreakpointValue,
} from "@chakra-ui/react";
import groupone from "../work/Wimg/sop.jpg";

export default function SplitScreen() {
  return (
    <Stack
      id="WorkSection"
      minH={"41vh"}
      bg="linear-gradient(90deg, rgba(185,31,168,1) 0%, rgba(217,112,179,1) 43%, rgba(238,166,186,1) 87%)"
      direction={{ base: "column", md: "row" }}
    >
      <Flex p={8} flex={1} align={"center"} justify={"center"}>
        <Stack spacing={6} w={"full"} maxW={"lg"}>
          <Heading fontSize={{ base: "3xl", md: "3xl", lg: "3xl" }}>
            <Text
              // ml={10}
              style={{ fontFamily: "Nunito Sans" }}
              fontWeight="600"
              color="white"
              as={"span"}
              position={"relative"}
              // _after={{
              //   content: "''",
              //   width: "full",
              //   height: useBreakpointValue({
              //     base: "20%",
              //     md: "10%",
              //     sm: "5%",
              //   }),
              //   position: "absolute",
              //   bottom: 1,
              //   left: 0,
              //   zIndex: -1,
              // }}
            >
           OUR ACHIEVEMENTS

            </Text>
            <br />{" "}
            {/* <Text
              style={{ fontFamily: "Nunito Sans" }}
              fontWeight={600}
              // ml={10}
              color={"#FFFFFF"}
              as={"span"}
            >
              philanthropists
            </Text>{" "} */}
          </Heading>
          <Text
            style={{ fontFamily: "Nunito Sans" }}
            fontSize={{ base: "md", lg: "lg" }}
            color={"#F]FFFFF"}
          >
         My City People is all about making your transition to a new city seamless and exciting. Begin by signing up and creating your profile, where you can share your interests and goals. Then, dive into a world of like-minded individuals and connect with those who share your values. 
          </Text>
        </Stack>
      </Flex>
      <Flex direction="column-reverse" flex={1}>
        <Image
          style={{ marginLeft: "30px", borderRadius: "35px" }}
          width={{lg:"55%",base:"62%"}}
          p={6}
          mb={10}
          alt={"Login Image"}
          objectFit={"fill"}
          src={groupone}
        />
      </Flex>
    </Stack>
  );
}
