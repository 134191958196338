import React from "react";
import {
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  // useBreakpointValue,
} from "@chakra-ui/react";
import Groupthree from "../points/Pimg/missios.jpg";

export default function SplitScreen() {
  return (
    <Stack
      minH={"41vh"}
      bg="linear-gradient(90deg, rgba(185,31,168,1) 0%, rgba(217,112,179,1) 43%, rgba(238,166,186,1) 87%)"

      direction={{ base: "column", md: "row" }}

      // p={10}
    >
      <Flex
        p={8}
        // ml="20px"
        alignItems="flex-start"
        flex={1}
        align={"center"}
        justify={"center"}
      >
        <Stack spacing={6} w={"full"} maxW={"lg"}>
          <Heading
          mt={10}
            fontWeight="600"
            fontSize={{ base: "3xl", md: "3xl", lg: "3xl" }}
          >
            <Text
              style={{ fontFamily: "Nunito Sans" }}
              color="white"
              as={"span"}
              
              position={"relative"}
              // _after={{
              //   content: "''",
              //   width: "full",
              //   height: useBreakpointValue({ base: "20%", md: "30%" }),
              //   position: "absolute",
              //   bottom: 1,
              //   left: 0,
              //   zIndex: -1,
              // }}
            >
             OUR MISSION

            </Text>
            <br />{" "}
            {/* <Text
              style={{ fontFamily: "Nunito Sans" }}
              color={"#FFFFFF"}
              as={"span"}
            >
              Charities, Featured Funders and
            </Text>{" "} */}
            {/* <br />{" "}
            <Text
              style={{ fontFamily: "Nunito Sans" }}
              color={"#FFFFFF"}
              as={"span"}
            >
              Philanthropists!
            </Text>{" "} */}
          </Heading>
          <Text
            style={{ fontFamily: "Nunito Sans" }}
            fontSize={{ base: "md", lg: "lg" }}
            color={"#FFFFFF"}
          >
         Our mission is to transform the experience of relocating to a new city. We are dedicated to connecting individuals who understand the value of forging new relationships, sharing experiences, and building a strong support system in an unfamiliar environment. 

          </Text>
        </Stack>
      </Flex>
      <Flex direction="column-reverse" flex={1}>
        <Image
          width="46.5%"
          height="60%"
        
          ml={{base:"45px",lg:"30px"}}
          alt={"Login Image"}
          objectFit={"cover"}
          borderRadius="6%"
          // height="18vh"
          src={Groupthree}
       
          mb={{base:"70px",lg:"70px"}}
        />
      </Flex>
    </Stack>
  );
}

