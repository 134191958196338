import {
  Container,
  Heading,
  Stack,
  Text,
  Button,
  Image,
  Box,
} from "@chakra-ui/react";
import PhilaFun from "./das.png";


export default function About() {
  return (
    <Container style={{ overflowX: "visible" }} id="AboutSection" maxW={"7xl"}>
      
      <Stack
        textAlign={"center"}
        align={"center"}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
      >
        <Heading
          fontSize={{ base: "3xl", sm: "3xl", md: "3xl" }}
          lineHeight={"110%"}
        >
          {" "}
          <Text
            style={{ fontFamily: "Nunito Sans" }}
            as={"span"}
            color={"#2F706D"}
        
            fontWeight="700"
          >
            About Us
          </Text>
        </Heading>
        <Stack spacing={6} direction={"row"}>
          {/* <Button
            px={12}
            py={9}
            lineHeight={"80%"}
            color="#DEC776"
            colorScheme={"#DEC776"}
           bg="#009BB5"
            // _hover={{ bg: 'orange.500' }}
          > */}
          <Box
         margin="auto"
       
          >
          <Image width="130px" src={PhilaFun} />
          </Box>
      
            
          {/* </Button> */}
        </Stack>

        <Text
          style={{ fontFamily: "Nunito Sans" }}
          color={"#2F706D"}
          textAlign="start"
          maxW={"4xl"}
        >
     At 'My City People,' our mission is to connect like-minded individuals who share the importance of forging new connections, sharing experiences, and building a strong support system in an unfamiliar environment. We believe that by connecting people, we can change the way we live in a world that often feels segregated and isolated.

<br /><br />
We are dedicated to establishing real-time, real-life connections for you. Our platform allows you to meet and hang out with like-minded individuals in person. We believe that the way we live has its challenges, and we are here to change that.
<br /><br />
At 'My City People,' we understand that these transitions can be both exciting and challenging. We are here to ensure that your journey to a new place is as seamless as possible.

<br /><br />
Sign up for 'My City People,' and we will transform new beginnings into incredible adventures. Together, we will forge connections that last a lifetime. Join us on this journey to reshape the way we connect and make your stay in a new city a truly unforgettable experience.

        </Text>
        <Heading
          fontWeight={500}
          fontSize={{ base: "8xl", sm: "3xl", md: "3xl" }}
          lineHeight={"210%"}
        >
          {" "}
        </Heading>
      </Stack>
      <Text
        as={"span"}
        style={{ fontFamily: "Nunito Sans",color:"rgb(10,19,50)",color:"linear-gradient(90deg, rgba(10,19,50,1) 0%, rgba(9,35,130,1) 45%, rgba(9,58,238,1) 76%)" }}
        fontWeight={700}
        fontSize={{ base: "3xl", sm: "3xl", md: "3xl" }}
        ml="12px"
       
      >
        How it works
      </Text>
    
    </Container>
  );
}
